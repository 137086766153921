@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
}

body {
  background-color: #101010;
  height: 100%;
}

strong, b {
  font-weight: bold !important;
}

.innerHtml p {
  margin-bottom: 20px;
}


.title-stroke, .title-stroke-secondary, .title-stroke-tertiary{
  color: black;
  -webkit-text-stroke: 1px #E60341;
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
  text-transform: uppercase;
}

.title-stroke-secondary {
  -webkit-text-stroke: 1px #0CA5E8;
}

.title-stroke-tertiary {
  -webkit-text-stroke: 1px #6C00F8;
}

.shrink-border {
  position: relative;
  &:active, &:hover {
    transform: translateY(0px);
  }
  &::after,
  &::before {
    border-radius: 8px;
    z-index: 100;
  }

  &:hover {
    background-color: transparent;
    box-shadow: none;
    color: #fff;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 3px solid darken(#f00, 10%);
    transition: opacity 0.3s, border 0.3s;
  }

  &:hover::before {
    opacity: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 3px solid #fff;
    opacity: 0;
    z-index: -1;
    transform: scaleX(1.1) scaleY(1.3);
    transition: transform 0.3s, opacity 0.3s;
  }

  &:hover::after {
    opacity: 1;
    transform: scaleX(1) scaleY(1);
  }
}

.bg-dark-gradient {
  background: rgb(200,200,200);
  background: linear-gradient(180deg, rgba(200,200,200,1) 15%, rgba(255,255,255,1) 50%, rgba(200,200,200,1) 85%);
}

.bg-error-input {
  background: black;
  background: black linear-gradient(90deg, #e6032337 15%, #e603239f 100%);
}

input::-webkit-calendar-picker-indicator {
  filter: invert(1);
}


.chevron-arrow {
  display: inline-block;
  border-right: 4px solid black;
  border-bottom: 4px solid black;
  width: 10px; height: 10px;
  transform: rotate(-135deg);
}

.iphone-x {
  position: relative;
  margin: 40px auto;
  width: 360px;
  height: 780px;
  background-color: #7371ee;
  background-image: linear-gradient(60deg, #7371ee 1%,#a1d9d6 100%);
  border-radius: 40px;
  box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919, 0px 0px 0px 20px #111;
  
  &:before,
  &:after{
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  
  // home button indicator
  &:after {
    bottom: 7px;
    width: 140px;
    height: 4px;
    background-color: #f2f2f2;
    border-radius: 10px;
  }
  
  // frontal camera/speaker frame
  &:before {
    top: 0px;
    width: 56%;
    height: 30px;
    background-color: #1f1f1f;
    border-radius: 0px 0px 40px 40px;
  }
  
  i,
  b,
  s,
  span {
    position: absolute;
    display: block;
    color: transparent;
  }
  
  // speaker
  i {
    top: 0px;
    left: 50%;
    transform: translate(-50%, 6px);
    height: 8px;
    width: 15%;
    background-color: #101010;
    border-radius: 8px;
    box-shadow: inset 0px -3px 3px 0px rgba(256, 256, 256, 0.2);
  }
  
  // camera
  b {
    left: 10%;
    top: 0px;
    transform: translate(180px, 4px);
    width: 12px;
    height: 12px;
    background-color: #101010;
    border-radius: 12px;
    box-shadow: inset 0px -3px 2px 0px rgba(256, 256, 256, 0.2);
    
    &:after {
      content: '';
      position: absolute;
      background-color: #2d4d76;
      width: 6px;
      height: 6px;
      top: 2px;
      left: 2px;
      top: 3px;
      left: 3px;
      display: block;
      border-radius: 4px;
      box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.5);
    }
  }
  
  // time
  s {
    top: 50px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    width: 100%;
    font-size: 70px;
    font-weight: 100;
    padding-top: 60px;
  }
  
  // action buttons
  span {
    bottom: 50px;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    left: 30px;
    
    & + span {
      left: auto;
      right: 30px;
    }
  }
}


.scrollable::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.scrollable::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #DFE9EB;
}

.scrollable::-webkit-scrollbar-track:hover {
  background-color: #B8C0C2;
}

.scrollable::-webkit-scrollbar-track:active {
  background-color: #B8C0C2;
}

.scrollable::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #E60341;
}

.scrollable::-webkit-scrollbar-thumb:hover {
  background-color: #FF2760;
}

.scrollable::-webkit-scrollbar-thumb:active {
  background-color: #E60341;
}


@font-face {
  font-family: 'Gabriella Heavy';
  src: url('/assets/Gabriella_Heavy.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
